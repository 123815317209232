// A mock function to mimic making an async request for data
import axios from 'axios';
 import { urls } from '../../url';
import { checkAccessToken } from '../auth/authApi';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const TOASTER_TIMEOUT = 1000;

const handleApiError = (error) => {
  const  response  = error;
  if (response) {
    if (response.status === 400) {
      handleUnauthorizedError();
    } else if(response.status === 401){
      handleBadRequestError(response);
    } 
    else{
      console.log( response)
    }
  }
};

const handleBadRequestError = async (response) => {
  const responseError = await response.data?.message || 'Something went wrong pleaase Login again';
  toast.error(responseError, {
    position: 'top-right',
    autoClose: 3000,
  });
  setTimeout(() => {
    window.location.reload();  
    // Cookies.remove('accessToken');
  }, 1000);
};

const handleUnauthorizedError = () => {
  toast.error('Session expired, Please Login Again!', {
    position: 'top-right',
    autoClose: TOASTER_TIMEOUT,
  });
  
  setTimeout(() => {
    window.location.reload();
    Cookies.remove('accessToken');
  }, 3000);
};


export const uploadUsers = async (file) => {
   const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  const formData = new FormData();
  formData.append('file', file, 'user-uploaded-excel-file.xlsx');

  try {
    const response = await axios.post(urls.uploadExcel, formData, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
      return response.data;
   
  } catch (error) {
     if (error.response && error.response.status === 400) {
      console.log( error.response)
       toast.error( error.response.data.data.message, {
        position: 'top-right',
        autoClose: 3000
      });
    }
    else{
      handleApiError()
    }
 
   }
};

export const fetchUserEmail = async () => {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  try {
    const response = await axios.get(urls.fetchEmail,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {

    handleApiError(error);
    }
};

export const emailBodyTemplate = async () => {
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  try {
    const response = await axios.get(urls.bodyTemplate,
      {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    handleApiError(error);
    }
};

export async function getHeaders(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getHeaders, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    return error;
   }
}

export async function getFromEmails(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const response = await fetch(urls.getFromEmails, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function uploadFromEmails(selectedEmailId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "id": selectedEmailId } };
    const response = await fetch(urls.uploadFromEmails, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getCC(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getCC, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getBcc(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getBcc, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getSubjectLine(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getSubjectLine, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export const uploadAttachment = async (formData) => {
  console.log(formData, 'formData formData');
  const accessToken = await checkAccessToken();
  if (!accessToken) {
    return;
  }

  try {
    let headers = {
      'Authorization': `Bearer ${accessToken}`,
    };

    if (formData instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    } else if (Array.isArray(formData)) {
      headers['Content-Type'] = 'application/json';
    } else {
      console.error('Unsupported formData type:', formData);
      return;
    }

    const response = await axios.post(
      urls.uploadFile,
      formData instanceof FormData ? formData : JSON.stringify(formData),
      { headers }
    );

    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};
export async function uploadEmailData(emailData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "content": emailData } };
    const response = await fetch(urls.uploadEmailBody, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',  
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    toast.success(data.data.message, {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT
    });
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getPreviewEmail(rowId) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "rowId": rowId } };
    const response = await fetch(urls.getPreviewEmail, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function EmailBlast() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const response = await fetch(urls.confirmEMailBlast, {
      method: "POST",
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    let data; 

    if (response.status === 400) {
      data = await response.json();
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    } else {
      data = await response.json();
      toast.success(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }

    return { data };  
  } catch (error) {
    handleApiError(error);
    }
}

export async function getEmailBlastStatus() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }

    const response = await fetch(urls.emailBlastStatus, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    }
}

export async function getEmailTemplateNames() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    // const payload = { "data": { "email": email } };
    const response = await fetch(urls.getTemplateNames, {
      method: "GET",
      // body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
    return error;
   }
}

export async function AddTemplateName(templateData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "templateName": templateData } };
    const response = await fetch(urls.saveTemplateName, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    let data; 

    if (response.status === 400) {
      data = await response.json();
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    } else {
      data = await response.json();
      toast.success(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }

    return { data };  
  } catch (error) {
    handleApiError(error);
    return error;
   }
}

export async function ValidateTemplateNames(name) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "templateName": name } };
    const response = await fetch(urls.validateTemplateNames, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    
     const data = await response.json();
 
    return { data };  
  } catch (error) {
    handleApiError(error);
    return error;
   }
}

export async function deleteTemplateName(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "templateId": id } };
    const response = await fetch(urls.deleteTemplateName, {
      method: "DELETE",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    
    let data; 

    if (response.status === 400) {
      data = await response.json();
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    } else {
      data = await response.json();
      toast.success(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
 
    return { data };  
  } catch (error) {
    handleApiError(error);
    return error;
   }
}

export async function loadTemplateData(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
  
    const payload = { "data": { "templateId": id } };
    const response = await fetch(urls.loadTemplateData, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
       
    const  data = await response.json(); 
    return { data };  
  } catch (error) {
    handleApiError(error);
    return error;
   }
}
// export async function getEmailTemplateAttahment(templateData) {
//   try {
//     const accessToken = await checkAccessToken();
//     if (!accessToken) {
//       return;
//     }
  
//     const payload = { "data": { "templateData": templateData } };
//     const response = await fetch(urls.getHeaders, {
//       method: "POST",
//       body: JSON.stringify(payload),
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${accessToken}`,
//       },
//     });

//     const data = await response.json();
//     return { data };
//   } catch (error) {
//     handleApiError(error);
//     return error;
//    }
// }

// export async function getEmailTemplateFromEMail(templateData) {
//   try {
//     const accessToken = await checkAccessToken();
//     if (!accessToken) {
//       return;
//     }
  
//     const payload = { "data": { "templateData": templateData } };
//     const response = await fetch(urls.getHeaders, {
//       method: "POST",
//       body: JSON.stringify(payload),
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${accessToken}`,
//       },
//     });

//     const data = await response.json();
//     return { data };
//   } catch (error) {
//     handleApiError(error);
//     return error;
//    }
// }


 