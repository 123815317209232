import React, { useEffect, useState } from 'react';
import {
    Button,
    TextField,
    Typography,
    Container,
    Paper,
    MenuItem
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { EditDomainAsync, createDomainAsync, createFromUserAsync, fecthAllDomainsAsync, fetchAllDomainData, fetchAllDomainDataAsync, fetchDomains, updateDomainData, updateEditDomainData } from '../adminSlice';
import './admin.css'
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const StyledTextField = styled(TextField)({
    marginBottom: '1rem',
    '& .MuiInputBase-root': {
        background: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE6B8B',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE6B8B',
        },
    },
    '& .MuiFormLabel-root': {
        color: '#000',
    },
});


const AdminFromUserRegister = () => {
    const dispatch = useDispatch();
    const [view, setView] = useState('default');

    const AllDomainNames = useSelector(fetchDomains)
    console.log(AllDomainNames)
    const AllDomains = AllDomainNames?.data?.domainData || [];
    console.log(AllDomains)

    const domainData = useSelector(fetchAllDomainData)
    // console.log(domainData)




    // const EditDomainData = domainData.data || []
    // console.log(EditDomainData)

    // useEffect(() => {
    //     dispatch(fetchAllDomainDataAsync());
    //   }, [dispatch]);

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        // setDummy(event.target.value)
        dispatch(updateDomainData({ ...domainData, data: { ...domainData?.data, [name]: value } }));
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm();

    useEffect(() => {
        dispatch(fecthAllDomainsAsync())
    }, [dispatch])

    const handleAddDomainClick = () => {
        setView('addDomain');
        reset();
    };

    const handleEditDomainClick = () => {
        setView('editDomain');
        reset();
    };

    const onSubmit = (data) => {
        console.log(data)
        const SelectedDomainId = data.domain;

        if (view === 'default') {
            console.log(SelectedDomainId);

            dispatch(createFromUserAsync({
                email: data.email,
                domainId: SelectedDomainId

            }));
        } else if (view === 'addDomain') {
            dispatch(createDomainAsync({
                domainName: data.domainName,
                clientId: data.clientId,
                tenantId: data.tenantId,
                secret: data.secret,
                scopes: data.scopes,
            }));
        }
        else if (view === 'editDomain') {
            console.log(data)
            dispatch(EditDomainAsync({
                domainId: data.domain,
                domainName: data.domainName,
                clientId: data.clientId,
                tenantId: data.tenantId,
                secret: data.secret,
                scopes: data.scopes,
            }));
        }
        reset();
    };

    const handleDomainChange = (event) => {
        const selectedDomainId = event.target.value;

        if (selectedDomainId) {
            dispatch(fetchAllDomainDataAsync(selectedDomainId));
        }
    };

    const handleClose = () => {
        setView("default")
        dispatch(fecthAllDomainsAsync())
        dispatch(updateEditDomainData())

    }


    const [dummy, setDummy] = useState("Dummy")

    return (
        <div>
            <Container maxWidth="xs" sx={{ marginTop: "4%", height: "36rem" }}>
                <Paper elevation={3} style={{ padding: 20 }}>

                    {(view === 'default' && (
                        <>
                            <div className='contentContainer'>
                                <Typography variant="h6" sx={{ marginBottom: '2%', }} gutterBottom>
                                    Register Domain
                                </Typography>
                                <div className='buttonContainer'>
                                    <Button sx={{
                                        background: "#4681f4",
                                        color: "white",
                                        marginRight: '2.5%',
                                        '&:hover': {
                                            // boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                                            background: "white",
                                            color: "black" // Add box-shadow effect on hover
                                        },
                                    }} variant="contained" onClick={handleAddDomainClick}>
                                        <AddIcon fontSize='small' />
                                        Add Domain
                                    </Button>
                                    or
                                    <Button sx={{
                                        background: "#4681f4",
                                        color: "white",
                                        marginLeft: "2.5%",
                                        '&:hover': {
                                            // boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)',
                                            background: "white",
                                            color: "black" // Add box-shadow effect on hover
                                        },
                                    }} variant="contained" onClick={handleEditDomainClick}>
                                        <EditIcon fontSize='small' />
                                        Edit Domain
                                    </Button>
                                </div>
                                <div className='underline'></div>
                            </div>

                        </>
                    ))}

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {(view === 'default' || view === 'addDomain' || view === 'editDomain') && (
                            <div>
                                {view === 'default' && (
                                    <>
                                        <Typography variant="h6" sx={{ marginBottom: '2%', marginTop: '4%', }} gutterBottom>
                                            Register From Users
                                        </Typography>
                                        <StyledTextField
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            {...register("email", {
                                                required: "Email is required",
                                                pattern: {
                                                    value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                                                    message: "Email is not valid",
                                                },
                                            })}
                                            error={!!errors.email}
                                            helperText={errors.email && errors.email.message}
                                        />
                                        <StyledTextField
                                            select
                                            label="Select Domain"
                                            fullWidth
                                            margin="normal"
                                            {...register("domain", { required: "Domain is required" })}
                                            onChange={(e) => {
                                                register("domain", { required: false });
                                                if (!e.target.value) {
                                                    setError("domain", {
                                                        type: "required",
                                                        message: "Domain is required"
                                                    });
                                                } else {
                                                    setError("domain", null);
                                                }
                                            }}
                                            error={!!errors.domain}
                                            helperText={errors.domain && errors.domain.message}
                                        >
                                            {AllDomains.map((item, index) => (
                                                <MenuItem key={index} value={item.domainId}>{item.domainName}</MenuItem>
                                            ))}
                                        </StyledTextField>
                                        {/* {errors.domain && <p className="text-danger">{errors.domain.message}</p>} */}
                                    </>
                                )}

                                {(view === 'addDomain') && (

                                    <div>
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <IconButton>
                                                    <AddIcon />
                                                </IconButton>

                                                <Typography variant="body1" sx={{ fontWeight: 'bold', marginLeft: '5px' }}>Add Domain</Typography>

                                                <IconButton onClick={handleClose} sx={{ marginLeft: 'auto', marginRight: '10px', color: '#b04040' }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>

                                            <StyledTextField
                                                id="domainName"
                                                label="Domain Name"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                {...register("domainName", { required: "Domain is required" })}
                                                error={!!errors.Domain}
                                                helperText={errors.Domain && errors.Domain.message}
                                            />
                                            {/* {errors.Domain && <p className="text-danger">{errors.Domain.message}</p>} */}
                                            <StyledTextField
                                                id="clientId"
                                                label="Client Id"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                {...register("clientId", { required: "Client ID is required" })}
                                                error={!!errors.clientId}
                                                helperText={errors.clientId && errors.clientId.message}
                                            />
                                            {/* {errors.clientId && <p className="text-danger">{errors.clientId.message}</p>} */}
                                            <StyledTextField
                                                id="tenantId"
                                                label="Tenant Id"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                {...register("tenantId", { required: "Tenant ID is required" })}
                                                error={!!errors.tenantId}
                                                helperText={errors.tenantId && errors.tenantId.message}
                                            />
                                            {/* {errors.tenantId && <p className="text-danger">{errors.tenantId.message}</p>} */}
                                            <StyledTextField
                                                id="secret"
                                                label="Secret"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                {...register("secret", { required: "Secret is required" })}
                                                error={!!errors.secret}
                                                helperText={errors.secret && errors.secret.message}
                                            />
                                            {/* {errors.secret && <p className="text-danger">{errors.secret.message}</p>} */}
                                            <StyledTextField
                                                id="scopes"
                                                label="Scopes"

                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                {...register("scopes", { required: "Scopes is required" })}
                                                error={!!errors.scopes}
                                                helperText={errors.scopes && errors.scopes.message}
                                            />
                                            {/* {errors.scopes && <p className="text-danger">{errors.scopes.message}</p>} */}
                                        </>
                                    </div>
                                )}

                                {(view === 'editDomain') && (

                                    <div>
                                        <>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>

                                                <Typography variant="body1" sx={{ fontWeight: 'bold', marginLeft: '5px' }}>Edit Domain</Typography>

                                                <IconButton onClick={handleClose} sx={{ marginLeft: 'auto', marginRight: '10px', color: '#b04040' }}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </div>

                                            <StyledTextField
                                                select
                                                label="Select Domain"
                                                fullWidth
                                                margin="normal"
                                                {...register("domain", { required: "Domain is required" })}
                                                onChange={handleDomainChange}
                                                error={!!errors.domain}
                                                helperText={errors.domain && errors.domain.message}
                                            >
                                                {AllDomains.map((item, index) => (
                                                    <MenuItem key={index} value={item.domainId}>{item.domainName}</MenuItem>
                                                ))}
                                            </StyledTextField>

                                            {domainData.data && (
                                                <div>
                                                    <StyledTextField
                                                        id="Domain"
                                                        name="domainName"
                                                        value={domainData.data?.domainName || ""}
                                                        // value={dummy}
                                                        label="Domain"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        {...register("domainName", { required: "Domain Name is required" })}
                                                        onChange={handleFieldChange}
                                                        error={!!errors.Domain}
                                                        helperText={errors.Domain && errors.Domain.message}
                                                    />
                                                    {/* {errors.Domain && <p className="text-danger">{errors.Domain.message}</p>} */}
                                                    <StyledTextField
                                                        id="clientId"
                                                        name="clientId"
                                                        value={domainData.data?.clientId || ""}
                                                        label="Client Id"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        {...register("clientId", { required: "Client ID is required" })}
                                                        onChange={handleFieldChange}

                                                        // {...register("clientId", { required: "Client ID is required" })}
                                                        error={!!errors.clientId}
                                                        helperText={errors.clientId && errors.clientId.message}
                                                    />
                                                    {/* {errors.clientId && <p className="text-danger">{errors.clientId.message}</p>} */}
                                                    <StyledTextField
                                                        id="tenantId"
                                                        name="tenantId"
                                                        value={domainData.data?.tenantId || ""}

                                                        label="Tenant Id"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        {...register("tenantId", { required: "Tenant ID is required" })}
                                                        onChange={handleFieldChange}
                                                        // {...register("tenantId", { required: "Tenant ID is required" })}
                                                        error={!!errors.tenantId}
                                                        helperText={errors.tenantId && errors.tenantId.message}
                                                    />
                                                    {/* {errors.tenantId && <p className="text-danger">{errors.tenantId.message}</p>} */}
                                                    <StyledTextField
                                                        id="secret"
                                                        name="secret"
                                                        value={domainData.data?.secret || ""}
                                                        label="Secret"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        {...register("secret", { required: "Secret is required" })}
                                                        onChange={handleFieldChange}

                                                        // {...register("secret", { required: "Secret is required" })}
                                                        error={!!errors.secret}
                                                        helperText={errors.secret && errors.secret.message}
                                                    />
                                                    {/* {errors.secret && <p className="text-danger">{errors.secret.message}</p>} */}
                                                    <StyledTextField
                                                        id="scopes"
                                                        name="scopes"
                                                        value={domainData.data?.scopes || ""}
                                                        label="scopes"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        {...register("scopes", { required: "Scopes is required" })}
                                                        onChange={handleFieldChange}
                                                        error={!!errors.scopes}
                                                        helperText={errors.scopes && errors.scopes.message}
                                                    />
                                                </div>
                                            )}


                                            {/* {errors.scopes && <p className="text-danger">{errors.scopes.message}</p>} */}
                                        </>
                                    </div>
                                )}

                                <Button
                                    variant="contained"
                                    sx={{
                                        background: "#003566",
                                        '&:hover': {
                                            background: "#023e8a"
                                        }
                                    }}
                                    fullWidth
                                    type="submit"
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </form>
                </Paper>
            </Container>
        </div>
    );
};

export default AdminFromUserRegister;