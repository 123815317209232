import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../../header/Navbar'

const UserLayout = () => {

  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  // Function to handle template selection
  const handleTemplateClick = (templateId) => {
    setSelectedTemplateId(templateId);
  };
  return (
    <div>
    <Navbar onTemplateClick={handleTemplateClick}/>  
    </div>
  )
}

export default UserLayout