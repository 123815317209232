
import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import './navbar.css'
import avatar from '../../../src/assets/sandler.jpg'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useDispatch, useSelector } from 'react-redux'
import { selectAdminClaim, selectLoggedInUser, signOutAsync } from '../auth/authSlice';
import { useSpring, animated, config } from 'react-spring';
import { Link as RouterLink } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import { emailTemplates } from '../user/templates';
import { deleteTemplateNameAsync, emailBodyTemplateAsync, getEmailTemplateNamesAsync, incrementTemplateClick, loadTemplateDataAsync, selectEmailTemplateNames, setTemplateId, updateEmailBody, updateTemplateAttachment, updateTemplateAttachmentSelectOneS, } from '../user/userSlice';
import {
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EmailIcon from '@mui/icons-material/Email';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { fetchEmailBlastStatusAsync, emailBlastStatusData } from '../user/userSlice.js'

const pages = [];
const settings = [{ name: 'Logout', link: '/logout' },];


const AnimatedSettingsMenu = ({ anchorElUser, onClose, userEmail, settings }) => {

  const dispatch = useDispatch();
  const menuAnimation = useSpring({
    opacity: Boolean(anchorElUser) ? 1 : 0,
    transform: Boolean(anchorElUser) ? 'translate3d(0, 0, 0)' : 'translate3d(0, -20px, 0)',
    vertical: 'top',
    horizontal: 'right',
    height: Boolean(anchorElUser) ? 200 : 0,
    config: {
      tension: 120, // Increase tension for stiffness
      friction: 14, // Increase friction for slower motion
    },
  });

  const handleLogout = () => {
    dispatch(signOutAsync());
    dispatch(updateEmailBody())
    dispatch(updateTemplateAttachment())
  }

  return (
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      TransitionComponent={(props) => (
        <animated.div style={menuAnimation} {...props} />
      )}
      open={Boolean(anchorElUser)}
      onClose={onClose}
    >
      <MenuItem style={{ color: "grey", cursor: 'default' }}>
        {userEmail.email}
      </MenuItem>
      {settings.map((item) => (
        <MenuItem style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleLogout}>
          {item.name}
        </MenuItem>
        // <a href={item.link} ></a>
      ))}

    </Menu>
  );
};


const EmailBlastStatusComponent = ({ emailsQueued, estimatedTime, statusLastUpdated }) => {
  const dispatch = useDispatch();

  const handleEmailBlastStatusRefresh = (() => {
    dispatch(fetchEmailBlastStatusAsync());
  })

  return (
    <Box sx={{ backgroundColor: '#1A4266', color: 'white', height: '55px', borderRadius: '5px', marginRight: 2, borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
      <Box sx={{ display: 'flex', flexFlow: "row wrap", fontSize: '15px' }}>
        {/* In Progress */}
        <Box sx={{ textAlign: 'right', marginRight: 2, marginLeft: 1 }}>
          <span>
            <CircleIcon color='primary' sx={{ height: '8px' }} />
            <b>Emails in Queue:</b> {emailsQueued}
          </span>
        </Box>

        {/* ETA */}
        <Box sx={{ textAlign: 'left', marginRight: 2 }}>
          <span>
            <CircleIcon color='secondary' sx={{ height: '8px' }} />
            <b>Estimated Time: </b> {estimatedTime}
          </span>
        </Box>
      </Box>
      <hr style={{ marginTop: 5, marginBottom: 3, color: 'grey' }}></hr>
      {/* Last Updated */}
      <Box sx={{ display: 'flex', flexFlow: "row wrap", justifyContent: 'flex-end' }}>
        <Box sx={{ fontSize: '10px', textAlign: 'right', marginRight: 2, marginLeft: 1, fontStyle: 'italic', fontWeight: 'medium' }}>
          <span><b>Last Updated:</b> {statusLastUpdated}</span>
          <Tooltip title="Click to Refresh">
            <RefreshIcon sx={{ fontSize: 'medium', marginLeft: 1, cursor: 'pointer' }} onClick={handleEmailBlastStatusRefresh}></RefreshIcon>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )

}

const Navbar = ({ onTemplateClick }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElTemplates, setAnchorElTemplates] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
const [selectedTemplateId, setSelectedTemplateId] = React.useState(null);

const handleOpenDeleteModal = (templateId) => {
  setSelectedTemplateId(templateId);
  setIsDeleteModalOpen(true);
};

const handleCloseDeleteModal = () => {
  setSelectedTemplateId(null);
  setIsDeleteModalOpen(false);
};

const handleTemplateDelete = (templateId) => {
  dispatch(deleteTemplateNameAsync(templateId));
  setAnchorElTemplates(null);
};



  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchEmailBlastStatusAsync());
  }, []);

  const emailBlastStatus = useSelector(emailBlastStatusData);
  const emailsQueued = emailBlastStatus?.data?.triggered ?? 0;
  const estimatedTime = emailBlastStatus?.data?.estimatedTime ?? 0;
  const statusLastUpdated = emailBlastStatus?.metaData?.requestTime ?? '';

  const role = useSelector(selectAdminClaim);
  const accessToken = Cookies.get('accessToken');
  const userEmail = jwt_decode(accessToken);

  useEffect(() => {
    dispatch(getEmailTemplateNamesAsync())
  }, [dispatch])

  const emailTemplateNames = useSelector(selectEmailTemplateNames)

  //handling user menu
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  //handling email templates
  const handleTemplateMenuOpen = (event) => {
    dispatch(getEmailTemplateNamesAsync())
    setAnchorElTemplates(event.currentTarget);
  }
  const handleTemplateMenuClose = () => setAnchorElTemplates(null);

  const handleTemplateClick = (templateId) => {
    dispatch(updateTemplateAttachmentSelectOneS())
    dispatch(setTemplateId(templateId));
     dispatch(loadTemplateDataAsync(templateId))
     dispatch(incrementTemplateClick());
    //  onTemplateClick(templateId);
    setAnchorElTemplates(null);
  };

  // const handleTemplateDelete = (templateId) => {
  //   console.log(`Deleted Template: ${templateId}`);
  //   dispatch(deleteTemplateNameAsync(templateId))
  //   setAnchorElTemplates(null);
  // };

  const handleRefreshClick = () =>{
    navigate('/user');
    dispatch(updateEmailBody())
    dispatch(updateTemplateAttachment())
    dispatch(updateTemplateAttachmentSelectOneS())
    
  } 

  return (
    <>
      <AppBar position="fixed" sx={{ backgroundColor: '#1A4266' }} id="navbar">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={avatar}
              style={{ height: '40px', width: '40px', marginRight: '2%' }}
              alt="Logo"
            />
              <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Email Blaster
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            <EmailBlastStatusComponent
              emailsQueued={emailsQueued}
              estimatedTime={estimatedTime}
              statusLastUpdated={statusLastUpdated}
            />

            <Tooltip title="Email Templates">
              <Button
                sx={{
                  color: 'white',
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  // marginRight: "-10px",

                }}
                endIcon={
                  Boolean(anchorElTemplates) ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )
                }
                onClick={handleTemplateMenuOpen}
              >
                {/* Email Templates */}
                <EmailIcon sx={{ fontSize: 25, marginRight: '-6px' }} />
              </Button>
            </Tooltip>

            <Menu
              anchorEl={anchorElTemplates}
              open={Boolean(anchorElTemplates)}
              onClose={handleTemplateMenuClose}
              // sx={{ mt: '5px' }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
 {emailTemplateNames?.data?.fields && emailTemplateNames.data.fields.length > 0 ? (
  emailTemplateNames.data.fields.map(({ templateId, templateName }) => (
    <MenuItem
      key={templateId}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
      }}
    >
      <ListItemText
        onClick={() => handleTemplateClick(templateId)}
        sx={{
          fontSize: '14px',
          cursor: 'pointer',
          marginRight: '10px',
        }}
      >
        {templateName}
      </ListItemText>
      <ListItemIcon
        sx={{
          minWidth: 'auto',
          color: 'red',
          cursor: 'pointer',
          alignSelf: 'center',
        }}
        onClick={() => handleOpenDeleteModal(templateId)}
      >
        <DeleteIcon />
      </ListItemIcon>
    </MenuItem>
  ))
) : (
  <MenuItem
    sx={{
      display: 'flex',
      justifyContent: 'center',
      padding: '8px 16px',
    }}
  >
    <ListItemText
      sx={{
        fontSize: '10px',
        color: 'gray',
      }}
    >
      NA
    </ListItemText>
  </MenuItem>
)}

            </Menu>


            {/* Home Button */}
            {role === 0 && (
              <Tooltip title="Go To Excel Upload">
                <IconButton
                  color="inherit"
                  onClick={handleRefreshClick}
                  style={{ marginRight: '10px' }}
                >
                  <HomeOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}

            {/* Dropdown for Email Templates */}

            {/* User Settings */}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open Settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="User Avatar" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <AnimatedSettingsMenu
                anchorElUser={anchorElUser}
                onClose={handleCloseUserMenu}
                userEmail={userEmail}
                settings={settings}
              />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
      open={isDeleteModalOpen}
      onClose={handleCloseDeleteModal}
      aria-labelledby="delete-confirmation-title"
      aria-describedby="delete-confirmation-description"
    >
      <DialogTitle id="delete-confirmation-title">
        Are you sure you want to delete this template?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-confirmation-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteModal} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleTemplateDelete(selectedTemplateId);
            handleCloseDeleteModal();
          }}
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
      <Outlet />
    </>
  );
};


export default Navbar;
