
 import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
 import { uploadUsers, fetchUserEmail, getCC, getBcc, getSubjectLine, getHeaders, getFromEmails, uploadFromEmails ,uploadEmailData, uploadAttachment, emailBodyTemplate, getPreviewEmail, EmailBlast, getEmailBlastStatus, getEmailTemplateNames, AddTemplateName, ValidateTemplateNames, deleteTemplateName, loadTemplateData} from './userApi';
  
 const initialState = {
   tableData: [],
   emailData: [],
   cc:[],
   bcc:[],
   subjectLine:[],
   headers:[],
   fromEmailData:[],
   emailBody:[],
   email:[],
   emailBlastStatusData: [],
   emailTemplateNames:[],
   validateTemplateNames:null,
   templateAttachment:[],
   templateId: null,
   templateClickCount: 0,
   templateAttachmentOne:[],
   templateAttachmentOneNew:[]
  
 }
 
 export const fetchedUserEmailAsync = createAsyncThunk(
   'user/fetchAllUsers',
   async () => {
     const response = await fetchUserEmail();
     return response.data;
   }
 );
 
 export const emailBodyTemplateAsync = createAsyncThunk(
   'user/emailBodyTemplate',
   async () => {
     const response = await emailBodyTemplate();
     return response.data;
   }
 );
 
 
 export const getCcAsync = createAsyncThunk(
   'user/getCC',
   async (rowID) => {
      const response = await getCC(rowID);
     return response.data;
   }
 );
 export const getBccAsync = createAsyncThunk(
   'user/getBcc',
   async (rowID) => {
     const response = await getBcc(rowID);
     return response.data;
   }
 );
 export const getSubjectLineAsync = createAsyncThunk(
   'user/getSubjectLine',
   async (rowID) => {
     const response = await getSubjectLine(rowID);
     return response.data;
   }
 );
 
 export const getPreviewEmailAsync = createAsyncThunk(
   'user/getPreviewEmail',
   async (rowID) => {
     const response = await getPreviewEmail(rowID);
     return response.data;
   }
 );
 
 export const getHeadersAsync = createAsyncThunk(
   'user/getHeaders',
   async (rowID) => {
     const response = await getHeaders(rowID);
     return response.data;
   }
 );
 
 export const getFromEmailsAsync = createAsyncThunk(
   'user/getFromEmails',
   async () => {
     const response = await getFromEmails();
     return response.data;
   }
 );
 
 export const uploadFromEmailsAsync = createAsyncThunk(
   'user/uploadFromEmails',
   async (selectedEmailId) => {
     const response = await uploadFromEmails(selectedEmailId);
     return response.data;
   }
 );
 
 export const uploadUsersAsync = createAsyncThunk(
   'user/uploadUsers',
   async (file) => {
      const response =   uploadUsers(file);
      console.log(response)
       return response.data;
   }
 );
 
 export const uploadEmailDataAsync = createAsyncThunk(
   'user/uploadEmailData',
   async (emailData) => {
      const response =   uploadEmailData(emailData);
      return response.data;
   }
 );
 
 export const uploadAttchmentAsync = createAsyncThunk(
   'user/uploadAttachment',
   async (formData) => {
      const response =   uploadAttachment(formData);
      return response.data;
   }
 );
 
 export const EmailBlastAsync = createAsyncThunk(
   'admin/EmailBlast',
   async () => {
     const response = await EmailBlast();
      return response.data;
   }
 );
 
 export const fetchEmailBlastStatusAsync = createAsyncThunk(
   'user/fetchEmailBlastStatus',
   async () => {
     const response = await getEmailBlastStatus();
     return response.data;
   }
 );
 
 export const getEmailTemplateNamesAsync = createAsyncThunk(
   'user/getEmailTemplates',
   async () => {
     const response = await getEmailTemplateNames();
     return response.data;
   }
 );
 
 export const addTemplateNameAsync = createAsyncThunk(
   'user/AddTemplateName',
   async (templateData) => {
     const response = await AddTemplateName(templateData);
     return response.data;
   }
 );
 
 export const ValidateTemplateNamesAsync = createAsyncThunk(
   'user/ValidateTemplateNames',
   async (name) => {
     const response = await ValidateTemplateNames(name);
     return response.data;
   }
 );
 export const deleteTemplateNameAsync = createAsyncThunk(
   'user/deleteTemplateName',
   async (id) => {
     const response = await deleteTemplateName(id);
     return response.data;
   }
 );
 export const loadTemplateDataAsync = createAsyncThunk(
   'user/loadTemplateData',
   async (id) => {
     const response = await loadTemplateData(id);
     return response.data;
   }
 );
  
 
 const userSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
     setTableData: (state, action) => {
       state.tableData = action.payload;
     },
     setUploadedUserData: (state, action) => {
       state.uploadedUserData = action.payload;
     },
     updateTemplateValidation: (state, action) => {  
       state.validateTemplateNames = null;      
     },
     updateTemplateAttachment: (state, action) => {  
       state.templateAttachment = [];      
     },
     setTemplateId: (state, action) => {
       state.templateId = action.payload;
     },
     updateEmailBody: (state, action) => {
       state.emailBody = null;
       state.templateId=null
     },
     updateTemplateId: (state, action) => {
       state.templateId=null
     },
     incrementTemplateClick: (state) => {
       state.templateClickCount += 1; 
     },
     updateTemplateAttachmentSelect: (state, action) => {
       console.log(action.payload.data , ' action.payload.data action.payload.data' )
       state.templateAttachment = action.payload;  
     },
     updateTemplateAttachmentSelectOne: (state, action) => {
      const { formatted, raw } = action.payload;
    
       const formData = new FormData();
      if (raw && Array.isArray(raw)) {
        raw.forEach((attachment) => {
          formData.append(`files`, attachment);  
        });
      } else {
        console.error("Raw attachments are null or not an array");
      }
          state.templateAttachmentOne = formatted;

       state.templateAttachmentOneNew = raw;
    },
    updateTemplateAttachmentSelectOneS: (state, action) => {
       state.templateAttachmentOne = []; // Update the global state with new data
       state.templateAttachmentOneNew = []; // Update the global state with new data
    },
   },
   extraReducers: (builder) => {
     builder
       .addCase(uploadUsersAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(uploadUsersAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         // Update state properties here as needed
       })
       .addCase(fetchedUserEmailAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(fetchedUserEmailAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.emailData = action.payload;
 
       })
       .addCase(getHeadersAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getHeadersAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.headers = action.payload;
       })
       .addCase(getCcAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getCcAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.cc = action.payload;
       })
       .addCase(getBccAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getBccAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.bcc = action.payload;
       })
       .addCase(getSubjectLineAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getSubjectLineAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.subjectLine = action.payload;
       })
       .addCase(getFromEmailsAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getFromEmailsAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.fromEmailData = action.payload;
       })
        .addCase(uploadEmailDataAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(uploadEmailDataAsync.fulfilled, (state, action) => {
         state.status = 'idle';
       })
       .addCase(uploadAttchmentAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(uploadAttchmentAsync.fulfilled, (state, action) => {
         state.status = 'idle';
       })
       .addCase(emailBodyTemplateAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(emailBodyTemplateAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.emailBody = action.payload
       })
       .addCase(getPreviewEmailAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getPreviewEmailAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.email = action.payload
       })
       .addCase(EmailBlastAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(EmailBlastAsync.fulfilled, (state, action) => {
         state.status = 'idle';
       })
       .addCase(fetchEmailBlastStatusAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(fetchEmailBlastStatusAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.emailBlastData = action.payload
       })
       .addCase(getEmailTemplateNamesAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(getEmailTemplateNamesAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.emailTemplateNames = action.payload
         console.log(state.emailTemplateNames,'emailTemplateNames emailTemplateNames' )
       })
       .addCase(addTemplateNameAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(addTemplateNameAsync.fulfilled, (state, action) => {
         state.status = 'idle';
        })
        .addCase(ValidateTemplateNamesAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(ValidateTemplateNamesAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.validateTemplateNames=action.payload
        })
        .addCase(deleteTemplateNameAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(deleteTemplateNameAsync.fulfilled, (state, action) => {
         state.status = 'idle';
        })
        .addCase(loadTemplateDataAsync.pending, (state) => {
         state.status = 'loading';
       })
       .addCase(loadTemplateDataAsync.fulfilled, (state, action) => {
         state.status = 'idle';
         state.emailBody=action.payload?.data
         state.templateAttachment=action.payload
        })
     
   },
 })
 
 export const { setTableData, setUploadedUserData , updateTemplateValidation,updateTemplateAttachment, setTemplateId , updateEmailBody, updateTemplateId , incrementTemplateClick, updateTemplateAttachmentSelect, updateTemplateAttachmentSelectOne , updateTemplateAttachmentSelectOneS} = userSlice.actions;
 export const { setEmailTemplate } = userSlice.actions;
 
 export const selectTableData = (state) => state.user.tableData;
 export const selectEmailData = (state) => state.user.emailData;
 export const selectCC = (state) => state.user.cc;
 export const selectBcC = (state) => state.user.bcc;
 export const selectSubjectLine = (state) => state.user.subjectLine;
 export const selectHeaders = (state) => state.user.headers;
 export const selectFromEmailData = (state) => state.user.fromEmailData;
 export const selectEmailBodyData = (state) => state.user.emailBody;
 export const selectPreviewEmail = (state) => state.user.email;
 export const emailBlastStatusData = (state) => state.user.emailBlastData;
 export const selectEmailTemplateNames = (state) => state.user.emailTemplateNames;
 export const selectValidateTemplateNames = (state) => state.user.validateTemplateNames
 export const selectTemplateAttachment = (state) => state.user.templateAttachment
 export const selectTemplateAttachmentOne = (state) => state.user.templateAttachmentOne
 export const selectTemplateAttachmentOneNew = (state) => state.user.templateAttachmentOneNew


 
 
 
 
 export default userSlice.reducer;
 