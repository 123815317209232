const server = process.env.REACT_APP_EMAIL_BLASTER_API_SERVICE_URL;

export const urls={
    login: `${server}/auth/login`,
    resgiter:`${server}/register`,
    logout:`${server}/auth/logout`,
    registeruser:`${server}/app/user-register`,
    getUsers:`${server}/app/user-data`,
    deleteUser:`${server}/app/user-update`,
    registerFromUser: `${server}/app/from-user-register`,
    getFromUsers: `${server}/app/from-user-data`,
    deleteFromUser: `${server}/app/from-user-update`,
    fromUserDomainRegister: `${server}/app/from-user/domain-register`,
    fromUserDomainUpdate: `${server}/app/from-user/domain-update`,
    fromUserDomainData: `${server}/app/from-user/domain-data`,
    refreshToken:`${server}/auth/refresh-token`,
    uploadExcel:`${server}/app/upload-excel`,
    fetchEmail:`${server}/app/email-editor/emails`,
    getCC:`${server}/app/email-editor/cc`,
    getBcc:`${server}/app/email-editor/bcc`,
    getSubjectLine:`${server}/app/email-editor/subject-line`,
    getHeaders:`${server}/app/email-editor/file-headers`,
    getFromEmails: `${server}/app/email-editor/from-emails`,
    uploadFromEmails: `${server}/app/email-editor/from-emails` ,
    uploadFile:`${server}/app/email-editor/upload-attachments`,
    uploadImages:`${server}/app/email-editor/upload-images`,
    uploadEmailBody:`${server}/app/email-editor/body`,
    bodyTemplate:`${server}/app/email-editor/body/template`,
    getPreviewEmail:`${server}/app/email-blast/preview`,
    confirmEMailBlast:`${server}/app/email-blast/confirm`,
    healthCheck:`${server}/health-check`,
    emailBlastStatus: `${server}/app/user-activity/email-blast-status`,
    saveTemplateName:`${server}/app/email-editor/user-email-templates/save`,
    getTemplateNames:`${server}/app/email-editor/user-email-templates/fetch`,
    validateTemplateNames: `${server}/app/email-editor/user-email-templates/validate`,
    loadTemplateData:`${server}/app/email-editor/user-email-templates/load`,
    deleteTemplateName:`${server}/app/email-editor/user-email-templates/remove`,
}



