


import React, { useState, useEffect, useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./emailCustomize.css";
import Navbar from "../../header/Navbar";
import EmailCompo from "./emailCompo";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build";
import { Button } from "@mui/material";
import EmailChips from "./emailChips";
import { useDispatch, useSelector } from "react-redux";
import {
  selectEmailBodyData,
  uploadAttchmentAsync,
  uploadEmailDataAsync,
  uploadFromEmailsAsync,
  emailBodyTemplateAsync,
  addTemplateNameAsync,
  getEmailTemplateNamesAsync,
  selectValidateTemplateNames,
  ValidateTemplateNamesAsync,
  updateTemplateValidation,
  selectTemplateAttachment,
  loadTemplateDataAsync,
  updateTemplateId,
  selectTemplateAttachmentOne,
  selectTemplateAttachmentOneNew,
} from "../userSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import InfoButton from "./infoButton";
import Typography from "@mui/material/Typography";
import { Modal, Box, TextField } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


const EmailCustomize = () => {
  const [loading, setLoading] = useState(true);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [selectedEmailId, setSelectedEmailId] = useState("");
  const [selectedSpinner, setSelectedSpinner] = useState("");
  const [contentError, setContentError] = useState("");
  const [fromEmailError, setFromEmailError] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedHeaders, setSelectedHeaders] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [validateTemplateError , setValidateTemplateError]=useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)
  const editorRef = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const emailBodyTemplateData = useSelector(selectEmailBodyData);
  const templateAttachment=useSelector(selectTemplateAttachment)
  console.log(templateAttachment , ' templateAttachment  templateAttachment')
    const navigate = useNavigate();

  const location = useLocation();

  const InfoButtonContent = (
    <Typography>
      <ul>
        <li>Attachments of a maximum size of 20 MB are allowed.</li>
        <li>“From Email” must be selected for generating Email Preview.</li>
        <li>Images of a maximum size of 15 MB are allowed.</li>
        <li>
          Attachment file formats supported: pdf, png, jpg, jpeg, xlsx, csv,
          doc, and docx.
        </li>
        <li>
        The maximum number of templates allowed per user is 10
        </li>
      </ul>
    </Typography>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      setIsInitialLoad(false);
    }, 3000);

    if (location.state) {
      const {
        emailBody,
        selectedAttachments,
        selectedEmailId,
        selectedEmail,
        selectedHeaders,
      } = location.state;
 
      const filelist = {
        FileList: selectedAttachments,
      };
      const arrayFromInput = filelist.FileList;
      const outputObject = {
        0: arrayFromInput[0],
        length: arrayFromInput.length,
      };
      setSelectedAttachments(selectedAttachments);
      setSelectedEmailId(selectedEmailId);
      setSelectedEmail(selectedEmail);
      setSelectedHeaders(selectedHeaders);
      if (emailBody) {
        setEmailBody(emailBody);
      } else {
        dispatch(emailBodyTemplateAsync());
      }
    }

    // if(loademailBodyTemplateData===null){
    //   dispatch(emailBodyTemplateAsync());
    // }
    if (!emailBodyTemplateData || emailBodyTemplateData.length === 0) {
      const response = dispatch(emailBodyTemplateAsync());
       setEmailBody(response?.payload?.data?.content)
    }
    return () => clearTimeout(timer);
    
  }, []);

  const handleAttachmentSelected = (newAttachments) => {
    setSelectedAttachments(newAttachments);
  };

  const handleEmailIdSelected = (id) => {
    setSelectedEmailId(id);
  };

  const handleSelectedSpinner = (id) => {
    setSelectedSpinner(id);
  };

  const handleSelectEmailAndHeaders = (email, headers) => {
    setSelectedEmail(email);
    setSelectedHeaders(headers);
  };

  const handleSaveClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setValidateTemplateError(false);
    setResponseValidateError("");
    setInputValue("");
   };

    const [responseValidateError, setResponseValidateError] = useState("");
   
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  

  useEffect(() => {
    if (!isInitialLoad && emailBodyTemplateData) {
       setLoading(true);  
      setTimeout(() => {
        setLoading(false);  
      }, 2000);  
    }
  }, [emailBodyTemplateData]);

  const processTemplateAttachment = (templateAttachment) => {
    console.log(templateAttachment)
     return templateAttachment?.data?.files?.map(({ fileName, ...rest }) => ({
      name: fileName,
      ...rest,
    }));
  };
  
  const transformedFiles = processTemplateAttachment(templateAttachment);
  console.log(transformedFiles, ' transformedFiles ' );
  const templateAttachmentOne=useSelector(selectTemplateAttachmentOne)

  const templateAttachmentValue = processTemplateAttachment(templateAttachmentOne)

  console.log(templateAttachmentOne )
  
  const attachmentsToSend = 
  templateAttachmentValue 
    ? templateAttachmentValue 
    : transformedFiles 
      ? transformedFiles 
      : selectedAttachments; 

      const newTemplateAttachmentUpload=useSelector(selectTemplateAttachmentOneNew)
      console.log(newTemplateAttachmentUpload , 'newTemplateAttachmentUpload newTemplateAttachmentUpload' )

  const handleConfirmClick = async () => {
    const emailData = editorRef.current.getData();
    const formData = new FormData();
  
    if (selectedAttachments && Array.isArray(selectedAttachments)) {
      selectedAttachments.forEach((attachment) => {
        formData.append(`files`, attachment);
      });
    } else {
      console.error("Selected attachments is null or not an array");
    }
  
    if (emailData.trim() === "") {
      setContentError("Email content is required");
      toast.error("Email Body is Required to Confirm!", { autoClose: 2000 });
      return;
    } else {
      setContentError("");
      toast.dismiss();
    }
  
    if (!selectedEmailId) {
      setFromEmailError("From Emails is required");
      toast.error("From Email is Required to Confirm!", { autoClose: 2000 });
      return;
    } else {
      setFromEmailError("");
      toast.dismiss();
    }
  
    setSelectedAttachments(selectedAttachments);
    setSelectedEmailId(selectedEmailId);
    setEmailBody(emailData);
    setSelectedEmail(selectedEmail);
    setSelectedHeaders(selectedHeaders);
     try {
      if (newTemplateAttachmentUpload && Array.isArray(newTemplateAttachmentUpload) && newTemplateAttachmentUpload.length > 0) {
        // Create a new FormData instance
        const formData = new FormData();
      
        // Append each file to the FormData
        newTemplateAttachmentUpload.forEach((file) => {
          formData.append('files', file);
        });
      
        // Dispatch the FormData with the files properly appended
        await dispatch(uploadAttchmentAsync(formData));
      } else if (transformedFiles) {
        await dispatch(uploadAttchmentAsync(transformedFiles));
      } else if (formData && [...formData.entries()].length > 0) {
        await dispatch(uploadAttchmentAsync(formData));
      } else {
        console.error("No attachments to send");
      }
  
      await dispatch(uploadEmailDataAsync(emailData));
      await dispatch(uploadFromEmailsAsync(selectedEmailId));
  
      const dataToPass = {
        selectedAttachments,
        selectedEmailId,
        emailBody: emailData,
        selectedEmail,
        selectedHeaders,
      };
  
      navigate("/user/previewEmail", { state: dataToPass });
      dispatch(updateTemplateId());
    } catch (error) {
      console.error("Error uploading attachments and email data:", error);
    }
  };

  const handleSave = async () => {
    if (inputValue.trim() === "") {
      setValidateTemplateError(true);  
      setResponseValidateError('template name required')
       return;  
    }
    try {
      const emailData = editorRef.current.getData();
      const formData = new FormData();

      if (selectedAttachments && Array.isArray(selectedAttachments)) {
        selectedAttachments.forEach((attachment) => {
          formData.append("files", attachment);
        });
      } else {
        console.error("Selected attachments is null or not an array");
      }

      setIsLoading(true);
      setIsSuccess(false);

       const response = await dispatch(ValidateTemplateNamesAsync(inputValue));
      if (response?.payload?.data?.isValid) {

        if (newTemplateAttachmentUpload && Array.isArray(newTemplateAttachmentUpload) && newTemplateAttachmentUpload.length > 0) {
          // Create a new FormData instance
          const formData = new FormData();
        
          // Append each file to the FormData
          newTemplateAttachmentUpload.forEach((file) => {
            formData.append('files', file);
          });
        
          // Dispatch the FormData with the files properly appended
          await dispatch(uploadAttchmentAsync(formData));
        } else if (transformedFiles) {
          await dispatch(uploadAttchmentAsync(transformedFiles));
        } else if (formData && [...formData.entries()].length > 0) {
          await dispatch(uploadAttchmentAsync(formData));
        } else {
          console.error("No attachments to send");
        }

        const emailDataResponse = await dispatch(uploadEmailDataAsync(emailData));
        if (emailDataResponse.error) throw new Error("Email data upload failed");

        const fromEmailsResponse = await dispatch(uploadFromEmailsAsync(selectedEmailId));
        if (fromEmailsResponse.error) throw new Error("From email upload failed");

        await sleep(10000);

        const templateResponse = await dispatch(addTemplateNameAsync(inputValue));
        if (templateResponse.error) throw new Error("Template name addition failed");

         setIsSuccess(true);
         setTimeout(() => {
          setIsLoading(false);
          setOpen(false);
        }, 2000);
        setInputValue("");
      } else {
        setValidateTemplateError(true);
        setResponseValidateError(response?.payload?.data?.message || "Validation failed");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error in handleSave:", error);
      setIsLoading(false);
    }
  };
  
  const templateId = useSelector((state) => state.user.templateId);
  const templateClickCount = useSelector((state) => state.user.templateClickCount);

  // useEffect(() => {
  //   const fetchTemplateData = async () => {
  //     try {
  //       if (templateId || templateClickCount) {          
  //         const response = await dispatch(loadTemplateDataAsync(templateId));
  //         const data=response?.payload?.data?.content
  //         setEmailBody(data);
  //         console.log(emailBody,"emailBody emailBody")
  //        }
  //     } catch (error) {
  //       console.error("Error loading template data:", error);
  //     }
  //   };
 
  //   fetchTemplateData();
  // }, [templateId, templateClickCount, dispatch]);

  useEffect(() => { 
        if (templateId) {          
          const response = dispatch(loadTemplateDataAsync(templateId));
          const data=response?.payload?.data?.content
          setEmailBody(data);
          console.log(emailBody,"emailBody emailBody")
         }   
   
  }, [templateId, templateClickCount, dispatch]);

  
console.log(attachmentsToSend , ' attachmentsToSend attachmentsToSend' );

  return (
    <>
      <div className="email-customize-container">
        <div className="component-content">
          <Navbar />
          <InfoButton toolTipContent={InfoButtonContent} />
          <div className="emailcustomize">
            <div className="one">
              <EmailCompo
                onSelectEmailAndHeaders={handleSelectEmailAndHeaders}
                selectedEmailIds={selectedEmail}
                selectedHeadersData={selectedHeaders}
                onEMailSelectedSpinner={handleSelectedSpinner}
              />
            </div>
            <div className="maindiv">
              <div className="two">
                <EmailChips
                  selectedAttachmentsId={attachmentsToSend}
                  selectedSpinner={selectedSpinner}
                  selectedEmailId={selectedEmailId}
                  onAttachmentSelected={handleAttachmentSelected}
                  onEmailIdSelected={handleEmailIdSelected}
                />
              </div>
              <div className="three">
                <div className="ckeditor-container">
                                    <CKEditor
                                        editor={Editor}
                                        data={ emailBody || emailBodyTemplateData?.content || '<p>Compose <br>Email Here!</p>'}
                                        config={{
                                            height: '49%',
                                            image: {
                                               resizeUnit: 'px'
                                            },
                                        }}
                                        onReady={(editor) => {
                                            editorRef.current = editor;
                                        }}
                                    />
                 </div>
                <div className="button-container">
                  <Tooltip title="Save Template" placement="top-start" arrow>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      onClick={handleSaveClick}
                    >
                      <EmailIcon sx={{marginRight: '5px'}}></EmailIcon>
                      Save Template
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="Move to Email Preview"
                    placement="top-start"
                    arrow
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleConfirmClick}
                    >
                      Next
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="loading-overlay">
            <CircularProgress />
          </div>
        )}
      </div>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {!isLoading ? (
            <>
              <Typography variant="h6" component="h2" mb={2}>
                Email Template Name
              </Typography>
              <TextField
                fullWidth
                label="Email Template Name"
                variant="outlined"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                error={validateTemplateError}
                helperText={validateTemplateError ? responseValidateError : ""}
              />
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  sx={{ mr: 2 }}
                >
                  Save
                </Button>
                <Button color="error" onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              sx={{ height: 200 }}
            >
              {isSuccess ? (
                <>
                  <CheckCircleOutlineIcon
                    color="success"
                    sx={{ fontSize: 50, mb: 2 }}
                  />
                  <Typography variant="h6" color="success.main">
                    Saved!
                  </Typography>
                </>
              ) : (
                <>
                  <CircularProgress />
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Saving
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default EmailCustomize;
