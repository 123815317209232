// A mock function to mimic making an async request for data
import { urls } from "../../url";
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const TOKEN_REFRESH_ERROR = 'TOKEN_REFRESH_ERROR';
const TOASTER_TIMEOUT = 1000;

const handleApiError = (response) => {
  if (response) {
    if (response.status === 400) {
      handleUnauthorizedError();
    } else  {
      handleBadRequestError(response);
    }
  }
};

export const handleBadRequestError = async (response) => {
    const responseError = await response.data?.message || 'Something went wrong pleaase Login again';
    toast.error(responseError, {
      position: 'top-right',
      autoClose: 3000,
    });
    setTimeout(() => {
      window.location.reload();  
      // Cookies.remove('accessToken');
    }, 1000);
  };

export const handleUnauthorizedError = () => {
   toast.error('Session expired, Please Login Again!', {
    position: 'top-right',
    autoClose: TOASTER_TIMEOUT,
  });
  setTimeout(() => {
    window.location.reload();  
    Cookies.remove('accessToken');
  }, 1000);
};

export function CreateUser(userData) {
  return new Promise(async (resolve) => {
    const response = await fetch(urls.resgiter, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    resolve({ data });
  });
}

export async function performHealthCheck() {
  try {
    const healthCheckResponse = await fetch(urls.healthCheck);
    const healthCheckData = await healthCheckResponse.json();

    if (healthCheckData.data.status === 'healthy') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Health check failed:', error);
    return false;
  }
}

export async function checkUser(loginInfo) {
  try {
    const isHealthy = await performHealthCheck();

    if (isHealthy) {
      var requestBody = { "data": loginInfo };
      const response = await fetch(urls.login, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: { 'content-type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        toast.success("Welcome to Email Blaster!", {
          position: 'top-right',
          autoClose: TOASTER_TIMEOUT,
        });

        if (data.data.tokens) {
          const accessToken = data.data.tokens.accessToken;
          const refreshToken = data.data.tokens.refreshToken;

          Cookies.set('accessToken', accessToken);
          Cookies.set('refreshToken', refreshToken);

          const decodedAccessToken = jwt_decode(accessToken);
          const decodedRefreshToken = jwt_decode(refreshToken);

          return { data, decodedAccessToken, decodedRefreshToken };
        } else {
          // throw new Error("Tokens are missing in the API response.");
        }
      } else {
        const error = await response.json();
        toast.error(error.data.message, {
          position: 'top-right',
          autoClose: TOASTER_TIMEOUT,
        });
        // throw new Error(error.data.message);
      }
    } else {
      toast.error('Whoops! Something went wrong, please contact Admin!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT,
      });
    }
  } catch (error) {
    console.error('An error occurred during login:', error);
    toast.error('Whoops! Something went wrong, please contact Admin!', {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT,
    });
   }
}


export async function signOut(userId) {
  const accessTokenCheck = await checkAccessToken();
  if (!accessTokenCheck) {
    window.location.reload();   
    return null;
  }
  
  const accessToken = Cookies.get('accessToken');
  const refreshToken = Cookies.get('refreshToken');

  return new Promise(async (resolve, reject) => {
    try {
      var requestBody = { "data": { "refreshToken": refreshToken } };
      const response = await fetch(urls.logout, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(requestBody),
      });
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');

      if (response.ok) {
        resolve({ data: "success" });
        toast.success('Goodbye!', {
          position: 'top-right',
          autoClose: TOASTER_TIMEOUT
        });
      } else {
        handleApiError(response);
        // const error = await response.text();
        // reject(error);
      }
    } catch (error) {
      // handleApiError(error);
      // reject(error);
    }
  });
}

let refreshTokenFailed = false;

export function refreshAccessToken(refreshToken) {
  if (refreshTokenFailed) {
    return
  }
  refreshTokenFailed = true
  const accessToken = Cookies.get('accessToken');
  return new Promise(async (resolve, reject) => {
    try {
      const requestBody = { "data": { "refreshToken": refreshToken } };
      const response = await fetch(urls.refreshToken, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });

      if (response.ok) {
        const data = await response.json();
        const newAccessToken = data.data.tokens.accessToken;

        // Store the new access token
        Cookies.set('accessToken', newAccessToken);
        resolve(newAccessToken);
      } else {
         handleApiError(response);
        //  resolve({ errorType: TOKEN_REFRESH_ERROR });
      }
    } catch (error) {
      // reject(error);
    }
  });
}

export function isAccessTokenExpired(decodedAccessToken) {
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedAccessToken.exp < currentTime;
}

export async function checkAccessToken() {
  const accessToken = Cookies.get('accessToken');
  if (!accessToken) {
    window.location.reload();   
    return null;
  }

  const decodedAccessToken = jwt_decode(accessToken);
  if (isAccessTokenExpired(decodedAccessToken)) {
     const refreshToken = Cookies.get('refreshToken');
     if (!refreshToken) {
      return
     }
    try {
      const result = await refreshAccessToken(refreshToken);
      if (result.errorType === TOKEN_REFRESH_ERROR) {
         handleUnauthorizedError();
      } else {
         Cookies.set('accessToken', result);
        return result;
      }
    } catch (error) {
      // Handle other errors
    }
  }

  return accessToken;
}

