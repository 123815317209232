import React, { useEffect, useRef, useState } from 'react';
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import './emailChip.css';
import MenuItem from '@mui/material/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import { selectBcC, selectCC, selectSubjectLine, updateTemplateAttachment, updateTemplateAttachmentSelect, updateTemplateAttachmentSelectOne, updateTemplateAttachmentSelectOneS, } from '../userSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { getFromEmailsAsync, selectFromEmailData } from '../userSlice';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';

const EmailChips = ({ selectedAttachmentsId, selectedEmailId, onAttachmentSelected, onEmailIdSelected, selectedSpinner }) => {

    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [showSpinner, setShowSpinner] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState('');
    const [selectedFile, setSelectedFile] = useState([])
    const [loadContent, setLoadContent] = useState(true)

    const ccData = useSelector(selectCC);
    const ccArray = ccData?.data?.fields[0]?.CC || [];

    const bcc = useSelector(selectBcC);
    const bccArray = bcc?.data?.fields[0]?.BCC || [];

    const subjectLine = useSelector(selectSubjectLine);
    const subjectLineArray = subjectLine?.data?.fields[0]?.subjectLine || [];

    const fromEmailData = useSelector(selectFromEmailData);
    const fields = fromEmailData?.data?.fields || [];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getFromEmailsAsync());
    }, [dispatch]);

    const selectEmail = (event) => {
        const selectedEmail = event.target.value;
        const selectedEmailObject = fields.find(item => item.email === selectedEmail);

        if (selectedEmailObject) {
            const selectedId = selectedEmailObject.id;
            setSelectedEmail(selectedEmailObject.email);
            onEmailIdSelected(selectedId);
        }

    };

    const handleAttachmentChange = (event) => {
         const files = event.target.files;      
        if (files.length > 0) {
          const maxSize = 20 * 1024 * 1024; // 20MB
          const selectedFile = files[0];
      
          if (selectedFile.size <= maxSize) {
            const newAttachments = Array.from(files);
      
             const formattedAttachments = {
              data: {
                files: newAttachments.map((file) => ({
                  fileName: file.name, 
                })),
              },
            };

            console.log( newAttachments , ' newAttachments  newAttachments  newAttachments')

            const formData = new FormData();
  
            if (newAttachments && Array.isArray(newAttachments)) {
                newAttachments.forEach((attachment) => {
                formData.append(`files`, attachment);
              });
            } else {
              console.error("Selected attachments is null or not an array");
            }
      
            dispatch(updateTemplateAttachmentSelectOne({formatted:formattedAttachments , raw:newAttachments}));
            // dispatch(updateTemplateAttachmentSelect(formattedAttachments));
            // setSelectedAttachments(newAttachments)
            // onAttachmentSelected(newAttachments)

             event.target.value = null;
          } else {
            toast.error("File size exceeds the limit (20MB)");
            event.target.value = null;
          }
        }
      };

    const handleRemoveFile = () => {
        setSelectedFile('');
        setSelectedAttachments([]);
        onAttachmentSelected([]);
        dispatch(updateTemplateAttachment())
        dispatch(updateTemplateAttachmentSelectOneS())


        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = '';
        }
    };


    const prevSelectedSpinner = useRef(selectedSpinner);

    useEffect(() => {
        const showSpinnerForOneSecond = () => {
            setShowSpinner(true);
            setTimeout(() => {
                setShowSpinner(false);
            }, 1000);
        };

        
        const selectedSpinnerChanged = selectedSpinner !== prevSelectedSpinner.current;

        if (selectedSpinnerChanged) {
             showSpinnerForOneSecond();
            setLoadContent(false);
        } else {
            // console.log("Not showing spinner");
        }

         prevSelectedSpinner.current = selectedSpinner;
    }, [selectedSpinner]);
 
    useEffect(() => {
        if (selectedEmailId && fields.length) {
            const selectedField = fields.find(item => item.id === selectedEmailId)
            if (selectedField) {
                setSelectedEmail(selectedField.email)
            }
        }

    }, [selectedEmailId, fields])

    useEffect(()=>{
        if(selectedAttachmentsId || selectedAttachmentsId.length===0){
          setSelectedAttachments(selectedAttachmentsId)
        }
    },[selectedAttachmentsId])

    console.log( selectedAttachmentsId , ' selectedAttachmentsId selectedAttachmentsId');
    
    return (
        <>
            <div className='conatainer'  >
                <div className='AttachmentStyles'>
                    <div className='file-style'>
                        <div className="labelStyles"  >Attachment</div>
                        <div className='iconContainer'>
                            <Tooltip title="Attachment file formats supported: docs, pdf, xlsx, csv, png, jpg, and jpeg" placement="bottom-start" >
                                <InfoIcon className='InfoIconButton' />
                            </Tooltip>
                        </div>
                        <div>
                            

                        </div>
                        

                    </div>
                    <div className='fileContainer' >
                                <Tooltip title="Select File to Upload" placement="bottom-start" arrow>
                                    <InputLabel
                                        htmlFor="fileInput"
                                        className='inputLabelStyling'
                                    >
                                        <UploadFileIcon />
                                        Select File
                                    </InputLabel>
                                </Tooltip>
                                <Input
                                    id="fileInput"
                                    type="file"
                                    accept=".pdf, .doc, .docx"
                                    onChange={handleAttachmentChange}
                                    endAdornment={
                                        <InputAdornment>
                                        </InputAdornment>
                                    }
                                    style={{ display: 'none' }}
                                // inputProps={{ multiple: true }}
                                />
                                {(selectedAttachments.length > 0 || selectedAttachmentsId?.length > 0) && (
                                    <div className='removeIcon'>
                                        <Tooltip title="Remove Attachment">
                                            <Button
                                                variant="outlined primary"
                                                color="secondary"
                                                onClick={handleRemoveFile}
                                                startIcon={<CloseIcon />}
                                                size="small"
                                                style={{ color: "black" }}
                                            >
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )}

                            </div>
                    <div className='AttachmentNameConatiner'>
                        {selectedAttachmentsId?.map((file, index) => (
                            <Tooltip key={index} title={file.name} placement="bottom-start" arrow>
                                <span
                                    className='attchmentName'
                                >
                                    {file.name}
                                </span>
                            </Tooltip>))}
                    </div>

                </div>
                <div className="chipContainerStyles">
                    <label className='selectLabelStyle' style={{}}>From Emails</label>
                    <Select
                        className='dropDownStyles'
                        value={selectedEmail}
                        defaultValue={selectedEmail}
                        onChange={selectEmail}
                    >
                        {fields.map((fromEmailItem) => (
                            <MenuItem key={fromEmailItem.id} value={fromEmailItem.email} className='optionField'>
                                {fromEmailItem.email}
                            </MenuItem>
                        ))}
                    </Select>

                    {showSpinner && (
                        <div className="loading-overlay">
                            <CircularProgress size={24} />
                        </div>
                    )}
                    <div className='component-content'>

                        {ccArray && ccArray.length > 0 ? (
                            <div className="scrollable-chip">
                                <label className='chiplLabel'  >CC</label>
                                <div className="scrollable-chip">
                                    {ccArray.map((ccEmail, index) => (
                                        <Chip
                                            key={index}
                                            label={ccEmail}
                                            title={ccEmail}
                                            color="primary"
                                            variant="outlined"
                                            className='chipStyles'
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="scrollable-chip">
                                <label className='chiplLabel'  >CC</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label="No CC found"
                                        color="primary"
                                        variant="outlined"
                                        className='chipStyles'

                                    />
                                </div>
                            </div>
                        )}

                        {bccArray && bccArray.length > 0 ? (
                            <div>
                                <label className='chiplLabel' >BCC</label>
                                <div className="scrollable-chip">
                                    {bccArray.map((bccEmail, index) => (
                                        <Chip
                                            key={index}
                                            label={bccEmail}
                                            title={bccEmail}
                                            color="secondary"
                                            variant="outlined"
                                            className='chipStyles'

                                        />
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <label className='chiplLabel'  >BCC</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label="No BCC found"
                                        color="secondary"
                                        variant="outlined"
                                        className='chipStyles'

                                    />
                                </div>
                            </div>
                        )}

                        {subjectLineArray && subjectLineArray.length > 0 ? (
                            <div className="scrollable-chip">
                                <label className='chiplLabel'  >Subject Line</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label={subjectLineArray}
                                        title={subjectLineArray}
                                        color="default"
                                        variant="outlined"
                                        className='chipStyles'

                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="scrollable-chip">
                                <label className='chiplLabel'  >Subject Line</label>
                                <div className="scrollable-chip">
                                    <Chip
                                        label="No Subject Line found"
                                        color="default"
                                        variant="outlined"
                                        className='chipStyles'

                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            
        </>
    );
};

export default EmailChips;